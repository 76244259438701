.claimhistory__no-content {
        max-width: 300px;
        margin: 1rem;
}

.history-status {
        &-30, &-50, &-70  {
                input {
                        -webkit-text-fill-color:  rgba(0, 186, 65, 0.4) !important;
                        color:  rgba(0, 186, 65, 0.4) !important;
                }
        }
        &-5, &-7, &-10, &-20, &-25,&-40,&-60 {
                input {
                        -webkit-text-fill-color:  rgba(247, 127, 14, 0.44) !important;
                        color: rgba(247, 127, 14, 0.4) !important;
                }

        } 
        &-80 ,&-90 {
                input {
                -webkit-text-fill-color:  rgba(255, 0, 0, 0.4) !important;
                color:  rgba(255, 0, 0, 0.4) !important;
                }
        }
}