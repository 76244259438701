.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,.7);
    z-index: 10;
    opacity: 0;
    transition: all .25s ease-in-out;
    &.show {
        opacity: 10;
    }
}
.layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
.primary-text {
    color: $primary-color;
}
.secondary-text {
    color: $secondary-text
}
.active-claim-number {
    padding: 2px 5px;
    border-radius: 5px;
    color: #EAB000;
    background-color: $primary-text
}
.contact-link {
    color: $primary-color;
}